module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hemsidan-home-splash","short_name":"grannskap®","start_url":"/","background_color":"#09796E","theme_color":"#09796E","display":"minimal-ui","icon":"src/images/grannskap-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4eebcc26c53924b8f3a5a5abe57c0147"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
